<template>
  <div>
    <b-row>
      <b-col>
        <h2 class="text-nowrap">
          Case:
          <router-link
            :to="'/cases/' + caseObject.CaseID + '/detail'"
          >
            {{ caseObject.FileNumber }}</router-link>
          - {{ caseObject.Status }}
        </h2>
      </b-col>
    </b-row>
    <hr>
    <div v-if="preparation && isClosing">
      <h2>Close Case</h2>
      <validation-observer ref="simpleRulesClose">
        <b-row>
          <b-col md="3">
            <validation-provider
              #default="{ errors }"
              name="Impact Rating"
              rules="required"
            >
              <b-form-group
                label="Impact Rating"
                label-for="impactRating"
                label-cols="7"
                class="required-data"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="impactRating"
                  v-model="ImpactRating"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="impactRatingOption"
                  placeholder=""
                  name="status"
                  :state="errors.length > 0 ? false : null"
                />
                <small
                  class="text-danger"
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="2">
            <div
              v-if="ImpactRating"
              class="pt-1"
            >
              {{ ImpactRating.value }}
            </div>
          </b-col>
          <b-col md="3">
            <b-form-group
              label-for="qualityOutstandingVideo"
            >
              <b-form-checkbox
                id="qualityOutstandingVideo"
                v-model="isQualityOutstandingVideo"
                name="qualityOutstandingVideo"
              >
                Quality/Outstanding Video
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <validation-provider
              #default="{ errors }"
              name="Reason for Rating"
              rules="required"
            >
              <b-form-group
                label="Reason for Rating"
                label-for="reasonForRating"
                class="required-data"
                :state="errors.length > 0 ? false : null"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedReasonForRating}"
                >
                  <textarea
                    v-model="ReasonForRating"
                    class="expandable-textarea"
                    :class="[isExpandedReasonForRating ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :state="errors.length > 0 ? false : null"
                  />
                  <div @click="isExpandedReasonForRating = !isExpandedReasonForRating">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedReasonForRating ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
                <small
                  class="text-danger"
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <div>Click Close Case to mark this case as Closed.</div>
        <div
          v-if="closeError"
          style="color: #EA5455"
        >
          {{ closeError }}
        </div>
        <div>
          <b-button
            type="reset"
            variant="outline-secondary"
            size="md"
            :disabled="!caseObject.CaseID || afterClick"
            @click="closeCase()"
          >
            Close Case
          </b-button>
        </div>
      </validation-observer>
    </div>
    <div v-else-if="!preparation">
      <h1>Case Reminder</h1>
      <div>Would you like to set a re-open reminder for case {{ caseObject.FileNumber }}?</div>
      <div>
        <b-button
          class="p-1"
          type="reset"
          variant="outline-secondary"
          size="md"
          @click="noBackToCase()"
        >
          No, Back to Case
        </b-button>
      </div>
      <hr>
      <b-form-group
        label="Remind on Date:"
        label-for="remindDate"
        class="col-3"
      >
        <b-form-input
          id="remindDate"
          v-model="RemindDate"
          type="date"
          placeholder=""
          name="remindDate"
        />
      </b-form-group>
      <b-row class="p-1">
        <b-col md="2">
          <b-button
            class="p-1 pl-2 pr-2"
            type="button"
            variant="outline-secondary"
            size="md"
            @click="remindOnDate(3)"
          >
            3 Months
          </b-button>
        </b-col>
        <b-col md="2">
          <b-button
            class="p-1 pl-2 pr-2"
            type="button"
            variant="outline-secondary"
            size="md"
            @click="remindOnDate(6)"
          >
            6 Months
          </b-button>
        </b-col>
        <b-col md="2">
          <b-button
            class="p-1 pl-2 pr-2"
            type="button"
            variant="outline-secondary"
            size="md"
            @click="remindOnDate(9)"
          >
            9 Months
          </b-button>
        </b-col>
        <b-col md="2">
          <b-button
            class="p-1 pl-2 pr-2"
            type="button"
            variant="outline-secondary"
            size="md"
            @click="remindOnDate(12)"
          >
            1 Year
          </b-button>
        </b-col>
      </b-row>
      <hr>
      <b-form-group
        label="Subject Birth Date"
        label-for="subjectBirthDate"
        class="col-3"
      >
        <b-form-input
          id="subjectBirthDate"
          :value="fullDateOfBirth ? fullDateOfBirth : SubjectBirthDate"
          type="text"
          placeholder=""
          name="subjectBirthDate"
          :readonly="true"
        />
        <div class="ice-icon">
          <feather-icon
            class="cursor-pointer"
            :icon="fullDateOfBirthIce? 'EyeOffIcon' : 'EyeIcon'"
            @click="toggleVisibilityFields('fullDateOfBirthIce')"
          />
        </div>
      </b-form-group>
      <b-form-group
        label="Notes (will be sent with reminder)"
        label-for="notes"
      >
        <div
          class="collapse-textarea"
          :class="{expanded: !isExpanded}"
        >
          <textarea
            v-model="Notes"
            class="expandable-textarea"
            :class="[isExpanded ? 'not-expanded' : 'expanded']"
            contentEditable
          />
          <div @click="isExpanded = !isExpanded">
            <span
              class="expand-arrow mt-n1"
              :class="[isExpanded ? 'not-expanded' : 'expanded']"
            >&#187;</span>
          </div>
        </div>
      </b-form-group>
      <b-button
        class="p-1"
        type="submit"
        variant="primary"
        size="md"
        @click="setReminder()"
      >
        Set Reminder
      </b-button>
    </div>
    <div v-else>
      <validation-observer ref="simpleRules">
        <h2>Reopen Case</h2>
        <div>Are you sure you wish to Reopen this Case?</div>
        <div>
          <b-button
            class="p-1"
            type="reset"
            variant="outline-secondary"
            size="md"
            @click="noBackToCase()"
          >
            No, Back to Case
          </b-button>
        </div>
        <hr>
        <b-row>
          <b-col md="3">
            <validation-provider
              #default="{ errors }"
              name="Budget Hours"
              rules="required|minValue:0"
            >
              <b-form-group
                label="Budget Hours"
                class="required-data"
                label-for="budgetHours"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  id="budgetHours"
                  v-model="BudgetHours"
                  type="number"
                  placeholder=""
                  name="budgetHours"
                />
                <small
                  class="text-danger"
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="New Budget $"
              label-for="budget"
            >
              <b-form-input
                id="budget"
                v-model="BudgetDollars"
                type="number"
                placeholder=""
                name="budget"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <validation-provider
              #default="{ errors }"
              name="Due Date"
              rules="required"
            >
              <b-form-group
                label="Due Date"
                class="required-data"
                label-for="caseDueDate"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  id="caseDueDate"
                  v-model="CaseDueDate"
                  type="date"
                  placeholder=""
                  name="caseDueDate"
                />
                <small
                  class="text-danger"
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Dates to be Conducted"
              label-for="datesConducted"
            >
              <div
                class="collapse-textarea"
                :class="{expanded: !isExpandedDatesConducted}"
              >
                <textarea
                  v-model="DatesConducted"
                  class="expandable-textarea"
                  :class="[isExpandedDatesConducted ? 'not-expanded' : 'expanded']"
                  contentEditable
                />
                <div @click="isExpandedDatesConducted = !isExpandedDatesConducted">
                  <span
                    class="expand-arrow mt-n1"
                    :class="[isExpandedDatesConducted ? 'not-expanded' : 'expanded']"
                  >&#187;</span>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <validation-provider
              #default="{ errors }"
              name="Case Received Source"
              rules="required"
            >
              <b-form-group
                label="Case Received Source"
                label-for="receivedSource"
                class="required-data"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-select
                  id="receivedSource"
                  v-model="ReceivedSource"
                  label="title"
                  :options="receivedSourceOptions"
                  placeholder=""
                  name="receivedSource"
                />
                <small
                  class="text-danger"
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <validation-provider
              #default="{ errors }"
              name="Reason for Reopen*"
              rules="required"
            >
              <b-form-group
                label="Reason for Reopen"
                class="required-data"
                label-for="reasonReopen"
                :state="errors.length > 0 ? false : null"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedReasonReopen}"
                >
                  <textarea
                    v-model="ReasonReopen"
                    class="expandable-textarea"
                    :class="[isExpandedReasonReopen ? 'not-expanded' : 'expanded']"
                    contentEditable
                  />
                  <div @click="isExpandedReasonReopen = !isExpandedReasonReopen">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedReasonReopen ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
                <small
                  class="text-danger"
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="6">
            <div
              class="white-bg-container border-gray"
              :class="{emptyField: emptyField}"
            >
              <ServiceDropdown
                v-for="(service, index) of Services"
                id="serviceDropdown"
                :key="index"
                :index="index"
                @deleteService="deleteService"
                @updateService="updateService"
              />
              <b-button
                type="button"
                variant="primary"
                class="w-50"
                @click="addService()"
              >
                <span>Add Service</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div>
          <b-button
            class="p-1"
            type="submit"
            variant="primary"
            size="md"
            :disabled="afterClick"
            @click="reopenCase()"
          >
            Reopen Case
          </b-button>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { extend } from 'vee-validate';
import { required, min_value } from 'vee-validate/dist/rules';
import ServiceDropdown from "@/views/cases/components/ServiceDropdown";
import {mapMutations} from "vuex";
import vSelect from "vue-select";

extend("minValue", min_value)
extend('required', required);

const apiService = new APIService();

export default {
  components: {
    ServiceDropdown,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      caseObject: {},
      closeError: null,
      isClosing: true,
      preparation: true,
      RemindDate: "",
      SubjectBirthDate: "",
      Notes: "",
      BudgetHours: 0,
      BudgetDollars: null,
      CaseDueDate: "",
      DatesConducted: "",
      ReasonReopen: "",
      Services: [{
        title: "",
        value: "",
      }],
      RequestedServices: "",
      ReasonForRating: "",
      isQualityOutstandingVideo: false,
      ImpactRating: null,
      impactRatingOption: [],

      isExpanded: false,
      isExpandedImpactRating: false,
      isExpandedReasonForRating: false,
      isExpandedDatesConducted: false,
      isExpandedReasonReopen: false,
      required,
      afterClick: false,

      emptyField: false,

      fullDateOfBirthIce: false,
      fullDateOfBirth: "",
      ReceivedSource: "",
      receivedSourceOptions: [
        {
          text: "Public Case Form",
          value: "Public Case Form",
        },
        {
          text: "Logged in Client",
          value: "Logged in Client",
        },
        {
          text: "Email",
          value: "Email",
        },
        {
          text: "Phone Call",
          value: "Phone Call",
        },
        {
          text: "DB Integration",
          value: "DB Integration",
        },
      ],
    }
  },
  watch: {
    Services(val) {
      const items = [];
      val.map(item => items.push(item.value))
      this.RequestedServices = items.join(',')
    },
    RequestedServices(val) {
      this.setRequestedServices(val)
    }
  },
  created() {
    this.getCase();
    this.getImpactRatings();
    this.setIsReadOnly(false)
    this.setRequestedServices("");
  },
  methods: {
    ...mapMutations({
      setRequestedServices: "cases/setRequestedServices",
      setIsReadOnly: "cases/setIsReadOnly",
    }),
    getCase() {
      const CaseID = this.$route.params.caseId;
      apiService
          .get("case/" + CaseID + "/detail")
          .then((res) => {
            this.caseObject = res.data;
            if(this.caseObject.Status === 'Closed') {
              this.isClosing = false;
            }
          })
    },
    addService() {
      const item = {
        title: "",
        value: "",
      };
      this.Services.push(item)
    },
    deleteService(index) {
      this.Services.splice(index, 1);
      if (!this.Services.length) this.addService();
    },
    updateService(val, index) {
      this.Services.splice(index, 1, val);
    },
    getImpactRatings() {
      apiService
          .get("impact-rating/list")
          .then(res => {
            this.impactRatingOption = res.data.map(item => {
              return {title: item.ImpactRate, value: item.ImpactReason, id: item.ImpactRatingID}
            });
          })
    },
    closeCase() {
      this.afterClick = true;
      this.$refs.simpleRulesClose.validate().then((success) => {
        if (success) {
          this.afterClick = true;
          const postData = {
            "ImpactReason": this.ReasonForRating,
            "isQualityOutstandingVideo": this.isQualityOutstandingVideo,
            "ImpactRate": this.ImpactRating.title,
            "CaseID": this.caseObject.CaseID
          }
          apiService
              .post("case/close", postData)
              .then( res => {
                this.afterClick = false;
                if (res) {
                  this.getCase();
                  this.preparation = false;
                  this.SubjectBirthDate = this.caseObject.Branch.Client.Subject.DateOfBirth;
                } else {
                  this.noBackToCase();
                }
              })
        }
      });
    },
    processForm() {
      this.afterClick = true;
      const postData =  {
        "BudgetHours": this.BudgetHours,
        "BudgetDollars": this.BudgetDollars,
        "CaseDueDate": this.CaseDueDate,
        "DatesToBeConducted": this.DatesConducted,
        "ReopenReason": this.ReasonReopen,
        "ReceivedSource": this.ReceivedSource,
        "RequestedServices": this.RequestedServices,
      }
      apiService
        .post("case/" + this.caseObject.CaseID + "/reopen", postData)
        .then( res => {
          if (res) {
            this.$router.push('/cases/' + res.data.CaseID + '/detail')
            this.setIsReadOnly(true)
            this.afterClick = false;
          }
        })

    },

    noBackToCase() {
      this.$router.push('/cases/' + this.caseObject.CaseID + "/detail");
    },

    remindOnDate(val) {
      let d = new Date();
      d.setMonth(d.getMonth() + val);
      this.RemindDate = d.toISOString().substr(0, 10);
    },

    setReminder() {
      const postData = {
        "RemindOnDate": this.RemindDate,
        "Notes": this.Notes,
      }
      apiService
          .post("case/" + this.caseObject.CaseID + "/reopen-reminder", postData)
          .then( () => {
            this.$router.push('/cases/' + this.caseObject.CaseID + "/detail");
          })
    },

    reopenCase() {
      this.emptyField = !this.RequestedServices
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.processForm();
        }
      });
    },

    toggleVisibilityFields() {
      this.fullDateOfBirthIce = !this.fullDateOfBirthIce;

      if (!this.fullDateOfBirth) {
        apiService
            .get("subject/" + this.caseObject.Branch.Client.Subject.SubjectID + "/pii")
            .then(res => {
              this.fullDateOfBirth = res.data.dateOfBirth
            })
      } else {
        this.fullDateOfBirth = "";
      }
    },
  },
}
</script>

<style scoped>
.border-gray {
  border: 1px solid #c7c7cc !important
}
.emptyField {
  border: 1px solid red !important;
}
.ice-icon {
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 3;
}
</style>